import React from "react";
import Layout from "../components/mainLayout/MainLayout";

const NotFoundPage = () => (
  <Layout withBackground>
    <div className="not-found">
      <div className="not-found__frame">
        <h1>Puslapis nerastas</h1>
        <p>
          Atsiprašome, tačiau gali būti, kad Jūs bandėte patekti į puslapį,
          kuris neegzistuoja.
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
